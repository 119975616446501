import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
	Alert,
	Button,
	Checkbox,
	DialogActions,
	DialogContent,
	FormControlLabel,
	Typography
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { Controller, useForm, useFormState, useWatch } from "react-hook-form";
import { Trans, getI18n } from "react-i18next";

import { ApolloClient, EDIT_ADDRESS } from "../../Api";
import { AppContext } from "../../context";
import { address, requiredFullName } from "../../util/validation";
import { ControlledTextField } from "../ControlledTextField";
import AddressForm from "./AddressForm";
import BuildModalPanel from "./BuildModalPanel";

const schema = yup.object().shape({
	fullName: requiredFullName(),
	shipping: address(),
	billing: address().when(
		["useShippingForBilling", "$allowBilling"],
		([useShippingForBilling, allowBilling], schema) =>
			useShippingForBilling && allowBilling ? schema : yup.mixed()
	)
});

const ShippingInfo = ({
	handleClose,
	orderDetailsInfo,
	hasRebills,
	countryCodeList,
	isShippingSameAsBilling,
	allowBilling
}) => {
	const form = useForm({
		resolver: yupResolver(schema),
		mode: "onTouched",
		defaultValues: {
			fullName: orderDetailsInfo?.shippingCustomer?.fullName ?? "",
			useShippingForBilling: isShippingSameAsBilling, // if they're the same, just use the shipping
			shipping: {
				...orderDetailsInfo?.shippingCustomer,
				address1: orderDetailsInfo?.shippingCustomer?.address1 ?? "",
				address2: orderDetailsInfo?.shippingCustomer?.address2 ?? "",
				city: orderDetailsInfo?.shippingCustomer?.city ?? "",
				state: orderDetailsInfo?.shippingCustomer?.state ?? "",
				phone: orderDetailsInfo?.shippingCustomer?.phone ?? ""
			},
			billing: {
				...orderDetailsInfo?.billingCustomer,
				address1: orderDetailsInfo?.billingCustomer?.address1 ?? "",
				address2: orderDetailsInfo?.billingCustomer?.address2 ?? "",
				city: orderDetailsInfo?.billingCustomer?.city ?? "",
				state: orderDetailsInfo?.billingCustomer?.state ?? "",
				phone: orderDetailsInfo?.billingCustomer?.phone ?? ""
			}
		},
		context: { shippable: true }
	});

	const [useShippingForBilling] = useWatch({
		control: form.control,
		name: ["useShippingForBilling"]
	});

	const [warningOpen, setWarningOpen] = useState(false);

	const {
		setAlertText,
		setAlert,
		setActionSuccessful,
		refetchOrderByReceiptNo,
		refetchOrderHistory,
		kountSessionId
	} = useContext(AppContext);

	const handleSuccess = () => {
		setActionSuccessful(true);
		setAlertText(
			<Trans i18nKey="EditInfoModal.shippingInfoSuccess">
				Your Shipping Information has been successfully updated.
			</Trans>
		);
		setAlert(true);
		if (window.location.pathname === "/orderHistory" && hasRebills) {
			refetchOrderHistory();
		} else {
			refetchOrderByReceiptNo(orderDetailsInfo.receiptNo);
		}
		handleClose();
	};

	const handleError = () => setWarningOpen(true);

	const [editAddressAction, { loading: editShippingLoading }] = useMutation(EDIT_ADDRESS, {
		onCompleted: handleSuccess,
		onError: handleError,
		client: ApolloClient
	});

	const submit = form.handleSubmit(
		({ fullName, shipping, billing }) => {
			const locale = getI18n()?.language;

			function addressToInput({ address1, address2, country, zip, city, state }) {
				return {
					addressOne: address1,
					addressTwo: address2,
					country,
					zip,
					city,
					state,
					fullName,
					locale
				};
			}

			const variables = {
				receipt: orderDetailsInfo?.receiptNo,
				kountSessionId,
				shippingInfo: addressToInput(shipping)
			};

			if (allowBilling) {
				variables.billingInfo = addressToInput(useShippingForBilling ? shipping : billing);
			}
			return editAddressAction({ variables });
		},
		fieldErrors => console.warn("Validation failed", fieldErrors)
	);
	const formState = useFormState({ control: form.control });

	return (
		<>
			<BuildModalPanel
				title={
					<Trans i18nKey="EditInfoModal.shippingInfoTitle">
						Edit Your Shipping Information
					</Trans>
				}
				description={
					<Trans i18nKey="EditInfoModal.shippingInfoDescription">
						This product has already shipped so any changes made will apply to the next
						shipment. Total amount is subject to change based on customer location and
						rate adjustments.
					</Trans>
				}
				handleCloseModalFn={handleClose}
			/>
			<DialogContent>
				<div className="no-mouseflow">
					<ControlledTextField
						control={form.control}
						name="fullName"
						id="name"
						label={<Trans i18nKey="EditInfoModal.name">Full Name</Trans>}
						fullWidth
						inputProps={{ maxLength: 255 }}
					/>
				</div>
				<Typography variant="h5" sx={{ padding: "1rem 0 .5rem" }}>
					<Trans i18nKey="EditInfoModal.shippingAddress">Shipping Address</Trans>
				</Typography>

				<div className="no-mouseflow">
					<AddressForm
						form={form}
						namePrefix="shipping."
						initial={orderDetailsInfo?.shippingCustomer}
						countryCodeList={countryCodeList}
						setWarningOpen={setWarningOpen}
					/>
				</div>
				{allowBilling && (
					<>
						<Typography variant="h5" sx={{ padding: "1rem 0 .5rem" }}>
							<Trans i18nKey="EditInfoModal.billingAddress">Billing Address</Trans>
						</Typography>

						<FormControlLabel
							control={
								<Controller
									control={form.control}
									name="useShippingForBilling"
									render={({ field }) => (
										<Checkbox {...field} checked={useShippingForBilling} />
									)}
								/>
							}
							label={
								<Trans i18nKey="EditInfoModal.label.useShippingAddress">
									Use Shipping Address
								</Trans>
							}
						/>
					</>
				)}
				{allowBilling && !useShippingForBilling && (
					<div className="no-mouseflow">
						<AddressForm
							form={form}
							namePrefix="billing."
							initial={orderDetailsInfo?.billingCustomer}
							countryCodeList={countryCodeList}
							setWarningOpen={setWarningOpen}
						/>
					</div>
				)}

				{warningOpen && (
					<Alert severity="error" sx={{ mt: 3 }}>
						<Trans i18nKey="EditInfoModal.shippingInfoError">
							An unknown error has occurred. To make updates to your shipping
							information please contact customer support directly.
						</Trans>
					</Alert>
				)}
			</DialogContent>
			<DialogActions sx={{ paddingTop: "1.5rem !important" }}>
				<Button color="secondary" onClick={handleClose}>
					<Trans i18nKey="EditInfoModal.cancelButton">Cancel</Trans>
				</Button>
				<LoadingButton
					color="primary"
					disabled={!formState.isValid || editShippingLoading}
					onClick={submit}
					loading={editShippingLoading}
				>
					<Trans i18nKey="EditInfoModal.saveChangesButton">Save Changes</Trans>
				</LoadingButton>
			</DialogActions>
		</>
	);
};

ShippingInfo.propTypes = {
	handleClose: PropTypes.func,
	orderDetailsInfo: PropTypes.object,
	countryCodeList: PropTypes.arrayOf(PropTypes.string),
	hasRebills: PropTypes.bool,
	isShippingSameAsBilling: PropTypes.bool,
	allowBilling: PropTypes.bool
};

export default ShippingInfo;
