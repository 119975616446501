import { useLazyQuery } from "@apollo/client";
import { Box, FormControl, InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { Trans } from "react-i18next";

import { FIND_LOCATION } from "../../Api";
import { countryList } from "../../constants";
import { ControlledTextField } from "../ControlledTextField";
import InputCircularProgress from "../InputCircularProgress";

// noinspection JSCommentMatchesSignature
/**
 * @param form {typeof import('react-hook-form').UseFormStateReturn}
 */
const AddressForm = ({ namePrefix, form, initial, countryCodeList, setWarningOpen }) => {
	const { control, formState, getFieldState, getValues, setValue } = form;

	const [country, zip] = useWatch({
		control,
		name: [`${namePrefix}country`, `${namePrefix}zip`]
	});

	const { invalid: zipInvalid } = getFieldState(`${namePrefix}zip`, formState);

	const [cityOptions, setCityOptions] = useState([]);
	const [stateOptions, setStateOptions] = useState([]);

	const locationErrorFunction = () => {
		setWarningOpen(true);
	};

	const [getLocationByZipCode, { loading: zipLoading, called: zipCalled }] = useLazyQuery(
		FIND_LOCATION,
		{
			context: {
				noAuth: true
			},
			onCompleted: ({ findLocation }) => {
				const cities = findLocation.cities;
				const states = findLocation.states;
				setCityOptions(cities);
				setStateOptions(states);

				const setCity = city => setValue(`${namePrefix}city`, city, { shouldTouch: true });
				const setState = state =>
					setValue(`${namePrefix}state`, state, { shouldTouch: true });
				if (cities.length === 0 || states.length === 0) {
					setCity("");
					setState("");
					return;
				}
				const city = getValues("city");
				if (!cities.includes(city)) {
					if (cities.includes(initial.city)) {
						setCity(initial.city);
					} else {
						setCity(cities[0]);
					}
				}

				const state = getValues("state");
				if (!states.includes(state)) {
					if (states.includes(initial.state)) {
						setState(initial.state);
					} else {
						setState(states[0]);
					}
				}
			},
			onError: locationErrorFunction
		}
	);

	useEffect(() => {
		// reload location data when zip becomes valid
		if (country === "US" && !zipInvalid) {
			getLocationByZipCode({ variables: { country, zipCode: zip } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [country, zip, zipInvalid]);

	const invalidUSZip =
		country === "US" && !zipLoading && (cityOptions.length === 0 || stateOptions.length === 0);

	const resetFields = () => {
		setValue(`${namePrefix}zip`, "");
		setValue(`${namePrefix}city`, "");
		setValue(`${namePrefix}state`, "");
	};

	return (
		<>
			<ControlledTextField
				control={control}
				name={`${namePrefix}address1`}
				id="address1"
				label={<Trans i18nKey="EditInfoModal.streetAddress">Street Address</Trans>}
				fullWidth
				required
				inputProps={{ minLength: 1, maxLength: 150 }}
				sx={{ marginTop: "4px" }}
			/>
			<ControlledTextField
				control={control}
				name={`${namePrefix}address2`}
				id="apt"
				label={<Trans i18nKey="EditInfoModal.apt_suit">Apartment/Suite/Other</Trans>}
				fullWidth
				inputProps={{ maxLength: 150 }}
				sx={{ marginTop: "4px" }}
			/>
			<Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="4px">
				<FormControl variant="filled" sx={{ width: "48%" }}>
					<InputLabel id="Country">
						<Trans i18nKey="shippingInfo.country">Country</Trans>
					</InputLabel>
					<Controller
						control={control}
						name={`${namePrefix}country`}
						render={({ field, fieldState }) => (
							<Select
								{...field}
								error={!!fieldState?.error}
								id="country"
								labelId="Country"
								onChange={e => {
									field.onChange(e);
									resetFields();
								}}
							>
								{countryCodeList.map((value, index) => (
									<MenuItem key={index} value={value}>
										{countryList[value]}
									</MenuItem>
								))}
							</Select>
						)}
					/>
				</FormControl>
				<ControlledTextField
					control={control}
					name={`${namePrefix}zip`}
					id="zipCode"
					label={<Trans i18nKey="EditInfoModal.zipCode">Zip Code</Trans>}
					sx={{ width: "48%", marginTop: 0 }}
					required
					error={invalidUSZip}
					helperText={
						invalidUSZip && (
							<Trans i18nKey="AddressForm.error.invalidZip">
								Please enter a valid zip or postal code.
							</Trans>
						)
					}
				/>
			</Box>
			<Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="4px">
				{country === "US" ? (
					<>
						<FormControl
							variant="filled"
							disabled={zipLoading}
							sx={{ position: "relative", width: "48%", height: "64px" }}
						>
							<InputLabel id="city">
								<Trans i18nKey="shippingInfo.city">City</Trans>
							</InputLabel>
							{!zipCalled || zipLoading ? (
								<InputCircularProgress top="12px" />
							) : (
								<Controller
									control={control}
									name={`${namePrefix}city`}
									render={({ field, fieldState }) => (
										<Select
											{...field}
											labelId="city"
											id="city"
											color={fieldState?.error ? "error" : undefined}
											error={!!fieldState?.error}
										>
											{cityOptions.map((value, index) => (
												<MenuItem key={index} value={value}>
													{value}
												</MenuItem>
											))}
										</Select>
									)}
								/>
							)}
						</FormControl>
						<FormControl
							variant="filled"
							disabled={zipLoading}
							sx={{ width: "48%", height: "64px" }}
						>
							<InputLabel id="state">
								<Trans i18nKey="shippingInfo.State">State</Trans>
							</InputLabel>
							{!zipCalled || zipLoading ? (
								<InputCircularProgress top="12px" />
							) : (
								<Controller
									control={control}
									name={`${namePrefix}state`}
									render={({ field, fieldState }) => (
										<Select
											{...field}
											labelId="state"
											id="state"
											color={fieldState?.error ? "error" : undefined}
											error={!!fieldState?.error}
										>
											{stateOptions.map((value, index) => (
												<MenuItem key={index} value={value}>
													{value}
												</MenuItem>
											))}
										</Select>
									)}
								/>
							)}
						</FormControl>
					</>
				) : (
					<>
						<ControlledTextField
							control={control}
							name={`${namePrefix}city`}
							label={<Trans i18nKey="shippingInfo.city">City</Trans>}
							required
							inputProps={{ minLength: 1, maxLength: 50 }}
							sx={{ width: "48%", marginTop: 0 }}
						/>
						<ControlledTextField
							required
							control={control}
							name={`${namePrefix}state`}
							label={<Trans i18nKey="shippingInfo.State">State</Trans>}
							inputProps={{ minLength: 1, maxLength: 50 }}
							sx={{ width: "48%", marginTop: 0 }}
						/>
					</>
				)}
			</Box>
		</>
	);
};

AddressForm.propTypes = {
	form: PropTypes.object,
	namePrefix: PropTypes.string,
	initial: PropTypes.object,
	countryCodeList: PropTypes.arrayOf(PropTypes.string),
	setWarningOpen: PropTypes.func
};

export default AddressForm;
