import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { DSPalette } from "@clickbank-ui/seller-design-system";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	DialogActions,
	DialogContent,
	FormControlLabel,
	Link,
	Typography
} from "@mui/material";
import PropTypes from "prop-types";
import React, { forwardRef, useContext, useRef, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import { Trans, getI18n } from "react-i18next";
import { IMaskInput } from "react-imask";

import { ApolloClient, EDIT_PAYMENT } from "../../Api";
import { AppContext } from "../../context";
import { address, ccExpDate, requiredFullName } from "../../util/validation";
import { ControlledTextField } from "../ControlledTextField";
import AddressForm from "./AddressForm";
import BuildModalPanel from "./BuildModalPanel";
import SecurityHelpTip from "./SecurityHelpTip";
import TokenEx from "./TokenEx";

const { palette } = DSPalette;

const TextMaskCustom = forwardRef((props, ref) => (
	<IMaskInput {...props} ref={ref} mask="00/00" placeholder="MM/YY" />
));

const TextMaskCustomCVV = forwardRef((props, ref) => (
	<IMaskInput {...props} ref={ref} mask="0000" placeholder="###" />
));

const schema = yup.object().shape({
	fullName: requiredFullName(),
	billing: address(),
	shipping: address().when(
		["useBillingForShipping", "$shippable"],
		([useBillingForShipping, isShippable], schema) =>
			!isShippable || useBillingForShipping ? yup.mixed() : schema
	),
	cvv: yup
		.string()
		.min(2)
		.required(
			<Trans i18nKey="PaymentInfo.error.cvvInvalid">
				Please enter a valid Security Code.
			</Trans>
		),
	cardHolderName: yup
		.string()
		.required(
			<Trans i18nKey="PaymentInfo.error.nameOnCardRequired">Name on Card is required.</Trans>
		),
	cardToken: yup
		.string()
		.when(["$tokenExStatus"], ([statusRef], schema) =>
			statusRef.current === "ok" ? schema : schema.required()
		),
	cardExpDate: yup
		.string()
		.test(
			"is-exp-valid",
			<Trans i18nKey="PaymentInfo.error.invalidDate">
				Please enter a valid Expiration Date.
			</Trans>,
			expDate => {
				const year = parseInt(new Date().getFullYear().toString().substring(2));
				return (
					new RegExp(ccExpDate).test(expDate) &&
					parseInt(expDate.split("/")[1]) >= year &&
					(parseInt(expDate.split("/")[1]) !== year ||
						// getMonth() is zero-indexed so + 1
						parseInt(expDate.split("/")[0]) >= parseInt(new Date().getMonth() + 1))
				);
			}
		)
});

const PaymentInfo = ({
	orderDetailsInfo,
	handleClose,
	acceptedCards,
	hasRebills,
	isRefundAuthFailState,
	countryCodeList,
	isShippable,
	isShippingSameAsBilling
}) => {
	const tokenExStatus = useRef("");
	const form = useForm({
		resolver: yupResolver(schema),
		mode: "onTouched",
		defaultValues: {
			fullName: orderDetailsInfo?.shippingCustomer?.fullName ?? "",
			cardHolderName: orderDetailsInfo?.cardHolderName ?? "",
			useBillingForShipping: isShippingSameAsBilling,
			shipping: {
				...orderDetailsInfo?.shippingCustomer,
				address1: orderDetailsInfo?.shippingCustomer?.address1 ?? "",
				address2: orderDetailsInfo?.shippingCustomer?.address2 ?? "",
				city: orderDetailsInfo?.shippingCustomer?.city ?? "",
				state: orderDetailsInfo?.shippingCustomer?.state ?? "",
				phone: orderDetailsInfo?.shippingCustomer?.phone ?? ""
			},
			billing: {
				...orderDetailsInfo?.billingCustomer,
				address1: orderDetailsInfo?.billingCustomer?.address1 ?? "",
				address2: orderDetailsInfo?.billingCustomer?.address2 ?? "",
				city: orderDetailsInfo?.billingCustomer?.city ?? "",
				state: orderDetailsInfo?.billingCustomer?.state ?? "",
				phone: orderDetailsInfo?.billingCustomer?.phone ?? ""
			}
		},
		context: { shippable: isShippable, tokenExStatus }
	});
	const setTokenExStatus = status => {
		tokenExStatus.current = status;
		form.trigger("cardToken");
	};

	const useBillingForShipping = form.watch("useBillingForShipping");

	//state declaration
	const [isSecurityHelpTipVisible, setSecurityHelpTipVisible] = useState(false);

	//touchState declaration
	const [warningOpen, setWarningOpen] = useState(false);

	const {
		setAlertText,
		setAlert,
		setActionSuccessful,
		showAlert,
		refetchOrderByReceiptNo,
		refetchOrderHistory,
		kountSessionId
	} = useContext(AppContext);

	const setToken = token => form.setValue("cardToken", token);
	const setTokenHash = tokenHash => form.setValue("cardTokenHash", tokenHash);
	const setCardType = cardType => form.setValue("cardType", cardType);
	const cardType = form.watch("cardType");

	//Functions
	const showAlertFunction = (text, isSuccessAction) => {
		!showAlert && setAlert(true);
		setAlertText(text);
		setActionSuccessful(isSuccessAction);
		handleClose();
	};

	const getAcceptedCards = () => {
		// If the payment card update is for a failed refund
		// limit the card type to the current card type
		if (isRefundAuthFailState) {
			return [orderDetailsInfo.tokenExPaymentMethod];
		} else {
			return acceptedCards;
		}
	};

	const handleSuccess = () => {
		showAlertFunction(
			isRefundAuthFailState ? (
				<Trans i18nKey="PaymentInfo.refundUpdateSuccess">
					Your Payment Information has been successfully updated. Please resubmit your
					refund request.
				</Trans>
			) : (
				<Trans i18nKey="PaymentInfo.updateSuccess">
					Your Payment Information has been successfully updated.
				</Trans>
			),
			true
		);
		if (window.location.pathname === "/orderHistory" && hasRebills) {
			refetchOrderHistory();
		} else {
			refetchOrderByReceiptNo(orderDetailsInfo.receiptNo);
		}
	};
	const handleError = () => {
		setWarningOpen(true);
	};

	const [editCustomerAction, { loading: editCustomerLoading }] = useMutation(EDIT_PAYMENT, {
		onCompleted: handleSuccess,
		onError: handleError,
		client: ApolloClient
	});

	const submit = form.handleSubmit(
		({
			fullName,
			shipping,
			billing,
			cardHolderName,
			cvv,
			cardExpDate,
			cardTokenHash,
			cardToken,
			cardType
		}) => {
			const locale = getI18n()?.language;

			function addressToInput({ address1, address2, country, zip, city, state }) {
				return {
					addressOne: address1,
					addressTwo: address2,
					country,
					zip,
					city,
					state,
					fullName,
					locale
				};
			}

			const variables = {
				receipt: orderDetailsInfo?.receiptNo,
				cardHolderName,
				cvv,
				expirationMonth: parseInt(cardExpDate.split("/")[0]),
				expirationYear: parseInt("20" + cardExpDate.split("/")[1]),
				cardTokenHash,
				cardToken,
				cardType,
				kountSessionId,
				billingInfo: addressToInput(billing)
			};

			if (isShippable) {
				variables.shippingInfo = addressToInput(useBillingForShipping ? billing : shipping);
			}

			return editCustomerAction({ variables });
		},
		fieldErrors => console.warn("Validation failed", fieldErrors)
	);

	const formState = useFormState({ control: form.control });

	return (
		<Box className="ms-form">
			<BuildModalPanel
				title={
					<Trans i18nKey="EditInfoModal.paymentInfoTitle">
						Edit Your Payment Information
					</Trans>
				}
				warning={
					<Trans i18nKey="EditInfoModal.paymentInfoWarning">
						Please note that we're unable to accommodate changing your payment method to
						PayPal. If you used a credit card for your initial purchase you may only add
						a new credit card instead of switching to a PayPal account.
					</Trans>
				}
				handleCloseModalFn={handleClose}
			/>
			<DialogContent>
				<Typography variant="h5" sx={{ padding: "1rem 0 .5rem" }}>
					<Trans i18nKey="EditInfoModal.paymentInfo">Payment Information</Trans>
				</Typography>
				<div className="no-mouseflow">
					<TokenEx
						setToken={setToken}
						setTokenHash={setTokenHash}
						cardType={cardType}
						setCardType={setCardType}
						cardsAccepted={getAcceptedCards()}
						setTokenExStatus={setTokenExStatus}
						isRefundAuthFailState={isRefundAuthFailState}
					/>

					<ControlledTextField
						fullWidth
						required
						control={form.control}
						name="cardHolderName"
						id="nameOnCard"
						label={<Trans i18nKey="EditInfoModal.nameOnCard">Name on Card</Trans>}
						inputProps={{ maxLength: 50 }}
						sx={{ marginTop: "4px" }}
					/>

					<Box display="flex" flexDirection="row" justifyContent="space-between">
						<ControlledTextField
							fullWidth
							required
							sx={{ width: "48%", marginTop: "4px" }}
							control={form.control}
							name="cardExpDate"
							id="expDate"
							label={
								<Trans i18nKey="EditInfoModal.expirationDate">
									Expiration Date
								</Trans>
							}
							InputProps={{
								inputComponent: TextMaskCustom
							}}
						/>
						<Box sx={{ width: "48%" }}>
							<ControlledTextField
								fullWidth
								required
								sx={{ marginTop: "4px" }}
								control={form.control}
								name="cvv"
								id="securityCode"
								label={
									<Trans i18nKey="EditInfoModal.securityCode">
										Security Code
									</Trans>
								}
								InputProps={{
									inputComponent: TextMaskCustomCVV
								}}
							/>
							<Link onClick={() => setSecurityHelpTipVisible(true)}>
								<Typography
									sx={{
										margin: "-4px 0 0",
										paddingLeft: "12px",
										fontSize: "14px",
										color: palette.primary.main
									}}
								>
									<Trans i18nKey="EditInfoModal.whatsThis">What is this?</Trans>
								</Typography>
							</Link>
						</Box>
					</Box>
				</div>
				{isSecurityHelpTipVisible && (
					<SecurityHelpTip handleToggle={() => setSecurityHelpTipVisible(false)} />
				)}

				<Typography variant="h5" sx={{ padding: "1rem 0 .5rem" }}>
					<Trans i18nKey="EditInfoModal.billingAddress">Billing Address</Trans>
				</Typography>

				<div className="no-mouseflow">
					<AddressForm
						namePrefix="billing."
						form={form}
						initial={orderDetailsInfo?.billingCustomer}
						countryCodeList={countryCodeList}
						setWarningOpen={setWarningOpen}
					/>
				</div>

				{isShippable && (
					<>
						<Typography variant="h5" sx={{ padding: "1rem 0 0" }}>
							<Trans i18nKey="EditInfoModal.shippingAddress">Shipping Address</Trans>
						</Typography>

						<FormControlLabel
							control={
								<Controller
									control={form.control}
									name="useBillingForShipping"
									render={({ field }) => (
										<Checkbox {...field} checked={useBillingForShipping} />
									)}
								/>
							}
							label={
								<Trans i18nKey="PaymentInfoModal.label.useBillingAddress">
									Use Billing Address
								</Trans>
							}
						/>
					</>
				)}
				{isShippable && !useBillingForShipping && (
					<div className="no-mouseflow">
						<ControlledTextField
							control={form.control}
							name="fullName"
							id="name"
							required
							label={<Trans i18nKey="EditInfoModal.name">Full Name</Trans>}
							fullWidth
							inputProps={{ maxLength: 255 }}
							sx={{ marginTop: "12px" }}
						/>
						<AddressForm
							namePrefix="shipping."
							form={form}
							initial={orderDetailsInfo?.shippingCustomer}
							countryCodeList={countryCodeList}
							setWarningOpen={setWarningOpen}
						/>
					</div>
				)}
				{warningOpen && (
					<Alert severity="error" sx={{ mt: 3 }}>
						<Trans i18nKey="EditInfoModal.paymentInfoError">
							An unknown error has occurred. Please check to make sure your updated
							credit card information is correct. If the error continues, please try
							another form of payment.
						</Trans>
					</Alert>
				)}
			</DialogContent>
			<DialogActions sx={{ paddingTop: "1.5rem !important" }}>
				<Button color="secondary" onClick={handleClose}>
					<Trans i18nKey="EditInfoModal.cancelButton">Cancel</Trans>
				</Button>
				<LoadingButton
					color="primary"
					disabled={!formState.isValid || editCustomerLoading}
					onClick={submit}
					loading={editCustomerLoading}
				>
					<Trans i18nKey="EditInfoModal.saveChangesButton">Save Changes</Trans>
				</LoadingButton>
			</DialogActions>
		</Box>
	);
};

PaymentInfo.propTypes = {
	orderDetailsInfo: PropTypes.object,
	handleClose: PropTypes.func,
	country: PropTypes.string,
	acceptedCards: PropTypes.arrayOf(PropTypes.string).isRequired,
	hasRebills: PropTypes.bool,
	isRefundAuthFailState: PropTypes.bool,
	countryCodeList: PropTypes.arrayOf(PropTypes.string),
	isShippable: PropTypes.bool,
	isShippingSameAsBilling: PropTypes.bool
};

export default React.memo(PaymentInfo);
