import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { Alert, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import { signOut } from "../../Api/Amplify";
import NotFindingOrder from "../../components/NotFindingOrder";
import OrderDetailsPanel from "../../components/OrderDetails";
import { AppContext } from "../../context";
import { getReceiptFromDolToken, isDOLUser } from "../../util/authHelpers";
import ContactCardsPanel from "../OrderLookup/ContactCardsPanel";

const breakpoints = DSBreakpoints.default;

const styles = {
	pageContent: {
		margin: "0 auto",
		padding: "20px 0",
		[breakpoints.down("md")]: {
			padding: "0"
		}
	},
	headerText: {
		marginBottom: "24px",
		[breakpoints.down("md")]: {
			marginBottom: "16px"
		}
	}
};

export default () => {
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [receipt, setReceipt] = useState(undefined);
	const [email, setEmail] = useState(undefined);
	const [hasLoaded, setHasLoaded] = useState(false);
	const [dolUser, setdolUser] = useState(false);

	const {
		alertText,
		setAlert,
		setAlertText,
		showAlert,
		isActionSuccessful,
		getOrderByReceiptNo,
		orderByReceiptData,
		orderByReceiptLoading,
		orderByReceiptError,
		getEmail,
		setUpdatedOrders
	} = useContext(AppContext);

	useEffect(() => {
		setdolUser(isDOLUser());

		getEmail();
		setUpdatedOrders([]);
		const urlParams = new URLSearchParams(window.location.search);
		const receiptNo = urlParams.get("receiptNo") || getReceiptFromDolToken();
		const ticketId = urlParams.get("ticketId");
		const email = urlParams.get("email");

		email && setEmail(email);
		if (receiptNo) {
			getOrderByReceiptNo(receiptNo, ticketId);
			setReceipt(receiptNo);
		} else {
			history.push("/orderHistory?status=NoReceiptSpecified");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!hasLoaded && orderByReceiptLoading) {
			setHasLoaded(true);
		}
		if (
			!orderByReceiptLoading &&
			orderByReceiptData?.orderByReceiptNo &&
			orderByReceiptData.orderByReceiptNo.receiptNo === receipt
		) {
			setLoading(false);
		}
		if (hasLoaded && orderByReceiptError) {
			if (email) {
				const handleSignOut = async () => {
					await signOut();
					history.push(`/?receiptNo=${receipt}&email=${encodeURIComponent(email)}`);
				};
				handleSignOut();
			} else {
				history.push("/orderHistory?status=UnableToFindOrder");
			}
		}
	}, [
		orderByReceiptData,
		orderByReceiptError,
		history,
		receipt,
		email,
		hasLoaded,
		setHasLoaded,
		orderByReceiptLoading
	]);

	useEffect(() => {
		if (showAlert) {
			setTimeout(() => {
				setAlert(false);
				setAlertText("");
			}, 60000);
		}
	}, [showAlert, setAlert, setAlertText]);

	useEffect(() => {
		showAlert &&
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
	}, [showAlert]);

	return (
		<>
			<Box sx={styles.pageContent}>
				<Box sx={styles.headerText}>
					<Typography variant="h2">
						<Trans i18nKey="OrderDetailsPage.orderDetailsTitle">
							Your Order Details
						</Trans>
					</Typography>
				</Box>
				{!dolUser && (
					<Button
						id="viewOrderHistoryButton"
						size="small"
						color="secondary"
						sx={styles.orderHistoryButton}
						onClick={() => history.push("/orderHistory")}
					>
						<Trans i18nKey="OrderDetailsPage.viewHistoryButton">
							View My Order History
						</Trans>
					</Button>
				)}
				{showAlert && (
					<Box sx={{ maxWidth: "680px", margin: "2rem 0" }}>
						<Alert severity={isActionSuccessful ? "success" : "error"}>
							{alertText}
						</Alert>
					</Box>
				)}
				{loading || !orderByReceiptData ? (
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						sx={{ minHeight: "300px" }}
					>
						<CircularProgress />
					</Grid>
				) : (
					<OrderDetailsPanel
						collapse={true}
						orderDetailsInfo={orderByReceiptData?.orderByReceiptNo}
						showRelatedOrders={true}
					/>
				)}
			</Box>
			<Grid container spacing={5}>
				<Grid item md={4}>
					<NotFindingOrder />
				</Grid>
				<Grid item md={8}>
					<ContactCardsPanel />
				</Grid>
			</Grid>
		</>
	);
};
