import { gql } from "@apollo/client";

export default gql`
	query ($receipt: String!) {
		receiptByReceiptNo(receipt: $receipt) {
			receiptNo
			discounts
			orderDate
			shipping
			taxes
			subtotal
			subtotalWithDiscounts
			total
			currencyCode
			paymentMethod
			last4
			shippingCustomer {
				id
				fullName
				email
				phone
				address1
				address2
				city
				state
				zip
				country
			}
			billingCustomer {
				id
				fullName
				email
				phone
				address1
				address2
				city
				state
				zip
				country
			}
			lineItems {
				id
				title
				quantity
				unitPrice
				subtotal
				orderType
				couponValue
				imagePath
				imageUrl
				imageAltText
				shippable
				recurring
				coupon {
					code
					couponType
					value
				}
				vendorNickname
			}
			taxAddress {
				name
				taxId
				address1
				address2
				city
				country
				state
				zip
			}
		}
	}
`;
