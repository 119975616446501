import { Box, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

// NOTE: Must be within a container with position: relative

const styles = {
	loader: {
		position: "absolute",
		left: "calc(50% - 12px)",
		zIndex: 100
	}
};

const InputCircularProgress = ({ top }) => {
	return (
		<Box sx={{ ...styles.loader, top: top }}>
			<CircularProgress size={24} />
		</Box>
	);
};

InputCircularProgress.propTypes = {
	top: PropTypes.string
};

export default InputCircularProgress;
