import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, DialogActions, DialogContent } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { Trans } from "react-i18next";

import { ApolloClient, EDIT_TAX_INFO } from "../../Api";
import { AppContext } from "../../context";
import { address, requiredFullName } from "../../util/validation";
import { ControlledTextField } from "../ControlledTextField";
import AddressForm from "./AddressForm";
import BuildModalPanel from "./BuildModalPanel";

const breakpoints = DSBreakpoints.default;

const styles = {
	root: {
		margin: "0 40px",
		[breakpoints.down("md")]: {
			margin: "0px 16px"
		}
	},
	rootWidth: {
		width: "50%",
		[breakpoints.down("md")]: {
			width: "89%"
		}
	}
};

const schema = yup.object().shape({
	name: requiredFullName(),
	address1: yup
		.string()
		.required(
			<Trans i18nKey="Common.error.address1Required">Street Address is required.</Trans>
		),
	city: yup
		.string()
		.required(<Trans i18nKey="Common.error.cityRequired">City is required.</Trans>),
	state: yup
		.string()
		.required(<Trans i18nKey="Common.error.stateRequired">State is required.</Trans>),
	taxId: yup
		.string()
		.required(
			<Trans i18nKey="TaxInfo.error.taxIdRequired">Tax/VAT/GST ID Number is required.</Trans>
		),
	address: address().when(
		["useBillingAddress", "$allowBilling"],
		([useBillingAddress, allowBilling], schema) =>
			useBillingAddress && allowBilling ? schema : yup.mixed()
	)
});

const TaxInfo = ({ handleClose, orderDetailsInfo, hasRebills, countryCodeList }) => {
	const initialAddress = orderDetailsInfo?.taxAddress;
	const form = useForm({
		resolver: yupResolver(schema),
		mode: "onTouched",
		defaultValues: {
			name: initialAddress?.name ?? "",
			taxId: initialAddress?.taxId ?? "",
			address1: initialAddress?.address1 ?? "",
			address2: initialAddress?.address2 ?? "",
			zip: initialAddress?.zip ?? "",
			city: initialAddress?.city ?? "",
			state: initialAddress?.state ?? "",
			country: initialAddress?.country ?? orderDetailsInfo.shippingCustomer?.country
		}
	});

	const [warningOpen, setWarningOpen] = useState(false);
	const {
		setAlertText,
		setAlert,
		setActionSuccessful,
		refetchOrderByReceiptNo,
		refetchOrderHistory
	} = useContext(AppContext);

	const handleSuccess = () => {
		setActionSuccessful(true);
		setAlertText(
			<Trans i18nKey="EditInfoModal.taxInfoSuccess">
				Your Tax Information has been successfully updated.
			</Trans>
		);
		setAlert(true);
		if (window.location.pathname === "/orderHistory" && hasRebills) {
			refetchOrderHistory();
		} else {
			refetchOrderByReceiptNo(orderDetailsInfo.receiptNo);
		}
		handleClose();
	};

	const handleError = () => setWarningOpen(true);

	const [editTaxInfoAction, { loading: editTaxLoading }] = useMutation(EDIT_TAX_INFO, {
		onCompleted: handleSuccess,
		onError: handleError,
		client: ApolloClient
	});

	const submit = form.handleSubmit(
		fields => {
			const variables = {
				receipt: orderDetailsInfo?.receiptNo,
				...fields
			};
			return editTaxInfoAction({ variables });
		},
		fieldErrors => console.warn("Validation failed", fieldErrors)
	);
	const formState = useFormState({ control: form.control });

	return (
		<>
			<BuildModalPanel
				title={
					<Trans i18nKey="EditInfoModal.taxInfoTitle">Edit Your Tax Information</Trans>
				}
				handleCloseModalFn={handleClose}
			/>
			<DialogContent>
				<ControlledTextField
					control={form.control}
					name="name"
					id="name"
					required
					label={<Trans i18nKey="EditInfoModal.name">Full Name</Trans>}
					fullWidth
					inputProps={{ maxLength: 255 }}
				/>

				<AddressForm
					form={form}
					namePrefix=""
					initial={orderDetailsInfo?.billingCustomer}
					countryCodeList={countryCodeList}
					setWarningOpen={setWarningOpen}
				/>

				<ControlledTextField
					control={form.control}
					name="taxId"
					id="taxId"
					required
					label={<Trans i18nKey="EditInfoModal.taxId">Tax/VAT/GST ID Number</Trans>}
					placeholder="00 000 000 000"
					fullWidth
					inputProps={{ maxLength: 255 }}
					sx={{ marginTop: "20px" }}
				/>
				{warningOpen && (
					<Alert severity="error" sx={{ mt: 3 }}>
						<Trans i18nKey="EditInfoModal.taxError">
							An unknown error has occurred. To make updates to your tax information
							please contact customer support directly.
						</Trans>
					</Alert>
				)}
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={handleClose} sx={styles.cancelButton}>
					<Trans i18nKey="EditInfoModal.cancelButton">Cancel</Trans>
				</Button>
				<LoadingButton
					color="primary"
					disabled={!formState.isValid || editTaxLoading}
					onClick={submit}
					sx={styles.saveButton}
					loading={editTaxLoading}
				>
					<Trans i18nKey="EditInfoModal.saveChangesButton">Save Changes</Trans>
				</LoadingButton>
			</DialogActions>
		</>
	);
};

TaxInfo.propTypes = {
	handleClose: PropTypes.func,
	orderDetailsInfo: PropTypes.object,
	countryCodeList: PropTypes.arrayOf(PropTypes.string),
	hasRebills: PropTypes.bool
};

export default TaxInfo;
