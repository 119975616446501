import { useLazyQuery, useMutation } from "@apollo/client";
import { DSBreakpoints, DSPalette } from "@clickbank-ui/seller-design-system";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";
import { Trans, getI18n } from "react-i18next";

import { ApolloClient, DIGITAL_PRODUCT_ACCESS, RESEND_SHIPPING } from "../../../Api";
import { AppContext } from "../../../context/AppContext";
import getRandomInt from "../../../util/randomID";
import AccessProductTooltip from "./AccessProductTooltip";
import RecurBillingInfo from "./RecurringBillingInfo";
import RecurringBillingState from "./RecurringBillingState";
import RefundAuthFailure from "./RefundAuthFailure";
import RefundOrCancelState from "./RefundOrCancelState";
import ShippingStatus from "./ShippingStatus";

const breakpoints = DSBreakpoints.default;
const { cbNeutral, palette } = DSPalette;

const styles = {
	root: {
		"& h3.MuiTypography-root, & h5.MuiTypography-root": {
			color: cbNeutral[400]
		}
	},
	buttonColumn: {
		"& .MuiButton-root": {
			marginTop: "1rem",
			whiteSpace: "normal"
		}
	},
	recurringBilling: {
		display: "flex",
		justifyContent: "center",
		height: "74px",
		marginTop: "24px",
		border: "1px solid #C8C7D1",
		borderRadius: "4px"
	},
	imageContainer: {
		[breakpoints.up("md")]: {
			marginBottom: "10px",
			padding: 0
		},
		"& img, & svg": {
			maxWidth: 160,
			height: "auto",
			paddingRight: "1rem"
		}
	},
	refundAuthFailure: {
		color: palette.error.dark,
		fontSize: "14px",
		fontWeight: 400
	}
};

const RecurringItem = ({
	lineItem,
	currencyCode,
	refundEligibility,
	refundExpDate,
	isRefundOrCancelState,
	isRefundAuthFailState,
	isResubmitRefundAllowed,
	refundAuthDate,
	receiptNo,
	isProductAccessible,
	isRefundEligible,
	isRefundRequestable,
	setTicketModalOpen,
	setTicketType,
	hasTickets,
	isSubscriptionCancelable,
	hasBeenRebilled,
	isResendShippingDetail,
	setInstructionsModalOpen
}) => {
	const options = { year: "numeric", month: "long", day: "numeric" };
	const { setAlertText, showAlert, setAlert, setActionSuccessful, email } =
		useContext(AppContext);

	// Safari appears to block asynchronous calls to `window.open`.
	// Create the window synchronously and then update the location asynchronously
	// https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari/70463940#70463940
	const newWindow = useRef(null);
	const [getAccessProductLink, { loading: linkLoading }] = useLazyQuery(DIGITAL_PRODUCT_ACCESS, {
		variables: { receipt: receiptNo, lineItemId: parseInt(lineItem.id) },
		onCompleted: data => {
			if (data?.digitalProductAccessLink && newWindow.current) {
				newWindow.current.location = data?.digitalProductAccessLink;
			} else {
				newWindow.current?.close();
			}
		}
	});

	const message = {
		SUCCESS: (
			<Trans i18nKey="OrderSummary.shippingSuccessMessage">
				An email containing your shipping details was sent to the following email address:{" "}
				{{ email }}
			</Trans>
		),
		FAILED: (
			<Trans i18nKey="OrderSummary.shippingFailedMessage">
				An error has occurred. An email containing your shipping details could not be sent
				to the following email address: {{ email }}
			</Trans>
		)
	};

	const handleShippingError = () => {
		!showAlert && setAlert(true);
		setAlertText(message.FAILED);
		setActionSuccessful(false);
	};

	const handleShippingSuccess = () => {
		!showAlert && setAlert(true);
		setAlertText(message.SUCCESS);
		setActionSuccessful(true);
	};

	const [resendShippingAction, { loading: shippingLoading }] = useMutation(RESEND_SHIPPING, {
		variables: {
			receipt: receiptNo,
			lineItemId: parseInt(lineItem.id, 10),
			language: getI18n()?.language
		},
		client: ApolloClient,
		onError: handleShippingError,
		onCompleted: handleShippingSuccess
	});

	const hasShipped = !!lineItem?.shippingNotice;
	const instructions = lineItem.product.vendor.physicalReturnInstructions;

	return (
		<>
			<Grid container direction="row" spacing={{ xs: 0, md: 8 }}>
				<Grid item xs={12} md={6} lg={7}>
					<Stack direction="row" pb={2}>
						{lineItem.product.imagePath && (
							<Box sx={styles.imageContainer}>
								<img
									src={lineItem.product.imageUrl}
									alt={lineItem.product.imageAltText}
								/>
							</Box>
						)}
						<Box>
							<Typography variant="h5" fontWeight={600}>
								{lineItem.title}
							</Typography>

							{lineItem.product.shippable && <ShippingStatus shipped={hasShipped} />}
						</Box>
					</Stack>

					{lineItem?.shippingNotice?.trackingNumber && (
						<Stack direction="row" pb={1}>
							<Typography variant="body2" sx={{ wordBreak: "break-word" }}>
								{lineItem.shippingNotice?.carrier}{" "}
								<Trans i18nKey="Common.shipping.trackingNumber">
									Tracking Number:
								</Trans>
								&nbsp;&nbsp;
								{lineItem.shippingNotice?.trackingUrl ? (
									<Link
										sx={{ display: "inline-block" }}
										href={lineItem?.shippingNotice?.trackingUrl}
										target="_blank"
										underline="always"
									>
										{lineItem?.shippingNotice?.trackingNumber}
									</Link>
								) : (
									lineItem?.shippingNotice?.trackingNumber
								)}
							</Typography>
						</Stack>
					)}

					<RecurBillingInfo lineItem={lineItem} currencyCode={currencyCode} />

					{refundEligibility() !== "REFUNDED" && (
						<Typography
							variant="caption"
							fontWeight={400}
							sx={{
								marginBottom: ".35em"
							}}
						>
							{refundEligibility() === "REFUND_ELIGIBLE" && (
								<>
									<Trans i18nKey="OrderDetails.refundEligibleText">
										Refund Eligible Through
									</Trans>
									<span> </span>
									<strong>
										{refundExpDate.toLocaleDateString(
											`${getI18n()?.language}-US`,
											options
										)}
									</strong>
								</>
							)}
							{refundEligibility() === "REFUND_ELIGIBLE_SHIPPED" && (
								<Trans i18nKey="OrderDetails.refundShippedText">
									Product has shipped, so please contact the vendor directly for
									assistance.
								</Trans>
							)}
							{refundEligibility() === "REFUND_NOT_ELIGIBLE" && (
								<Trans i18nKey="OrderDetails.refundNotEligibleText">
									Not eligible for a refund
								</Trans>
							)}
						</Typography>
					)}

					<Typography variant="body3">
						<Trans i18nKey="OrderDetails.recurProductDetails">
							The amount billed includes taxes. Total amount subject to change based
							on customer location and rate adjustments.
						</Trans>
					</Typography>
				</Grid>

				<Grid item xs={12} md={6} lg={5} sx={styles.buttonColumn}>
					{lineItem.paymentPlan?.activeStatus !== "CANCELED" && (
						<Box sx={styles.recurringBilling}>
							<RecurringBillingState
								lineItem={lineItem}
								options={options}
								hasBeenRebilled={hasBeenRebilled}
							/>
						</Box>
					)}

					<Box>
						{isRefundOrCancelState() && (
							<RefundOrCancelState
								lineItem={lineItem}
								isRefundAuthFailState={isRefundAuthFailState}
								isResubmitRefundAllowed={isResubmitRefundAllowed}
								refundAuthDate={refundAuthDate}
							/>
						)}

						{(isRefundAuthFailState || isResubmitRefundAllowed) && (
							<RefundAuthFailure
								isResubmitRefundAllowed={isResubmitRefundAllowed}
								setTicketModalOpen={setTicketModalOpen}
								setTicketType={setTicketType}
							/>
						)}

						{isProductAccessible() && (
							<>
								<LoadingButton
									fullWidth
									color="primary"
									id={"accessProductButton" + getRandomInt()}
									onClick={() => {
										newWindow.current = window.open();
										getAccessProductLink();
									}}
									disabled={linkLoading}
									loading={linkLoading}
								>
									<Trans i18nKey="OrderDetails.accessproductButton">
										Access This Product
									</Trans>
								</LoadingButton>

								<AccessProductTooltip />
							</>
						)}
						{isResendShippingDetail && (
							<LoadingButton
								fullWidth
								id={"resendEmail" + getRandomInt()}
								color="secondary"
								onClick={() => resendShippingAction()}
								disabled={showAlert || shippingLoading}
								loading={shippingLoading}
							>
								<Trans i18nKey="OrderDetails.resendShippingDetailsButton">
									Resend Shipping Details Email
								</Trans>
							</LoadingButton>
						)}
						{!hasTickets && (
							<>
								<Button
									fullWidth
									variant="outlined"
									color="primary"
									id={"productSupportButton3" + getRandomInt()}
									onClick={() => {
										setTicketType("techSupport");
										setTicketModalOpen(true);
									}}
								>
									<Trans i18nKey="OrderDetails.button.creatSupportTicket">
										Create Support Ticket
									</Trans>
								</Button>
								{isSubscriptionCancelable && (
									<Button
										fullWidth
										variant="outlined"
										color="error"
										id={"cancelSubscriptionButton" + getRandomInt()}
										onClick={() => {
											setTicketType("cancelSubscription");
											setTicketModalOpen(true);
										}}
									>
										<Trans i18nKey="OrderDetails.cancelSubButton">
											Cancel My Subscription
										</Trans>
									</Button>
								)}
								{isRefundRequestable && !hasShipped && (
									<Button
										fullWidth
										variant="outlined"
										color="warning"
										id={"reqRefundButton2" + getRandomInt()}
										onClick={() => {
											setTicketType("requestRefund");
											setTicketModalOpen(true);
										}}
									>
										<Trans i18nKey="OrderDetails.button.requestRefund">
											Request Refund
										</Trans>
									</Button>
								)}
								{isRefundEligible && hasShipped && instructions && (
									<Button
										fullWidth
										color="secondary"
										id={"reviewReturnInstructionsButton" + getRandomInt()}
										onClick={() => {
											setInstructionsModalOpen(true);
										}}
									>
										<Trans i18nKey="OrderDetails.button.reviewReturnInstructions">
											Review Return Instructions
										</Trans>
									</Button>
								)}
							</>
						)}
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

RecurringItem.propTypes = {
	lineItem: PropTypes.object,
	currencyCode: PropTypes.string,
	refundEligibility: PropTypes.func,
	refundExpDate: PropTypes.instanceOf(Date),
	isRefundOrCancelState: PropTypes.func,
	isRefundAuthFailState: PropTypes.bool,
	isResubmitRefundAllowed: PropTypes.bool,
	refundAuthDate: PropTypes.string,
	receiptNo: PropTypes.string,
	isProductAccessible: PropTypes.func,
	isRefundEligible: PropTypes.bool,
	isRefundRequestable: PropTypes.bool,
	setTicketModalOpen: PropTypes.func,
	setTicketType: PropTypes.func,
	hasTickets: PropTypes.bool,
	isResendShippingDetail: PropTypes.bool,
	isSubscriptionCancelable: PropTypes.bool,
	hasBeenRebilled: PropTypes.bool,
	setInstructionsModalOpen: PropTypes.func
};

export default RecurringItem;
