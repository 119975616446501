import { useMutation, useQuery } from "@apollo/client";
import { DSBreakpoints, DSPalette, DSTypography } from "@clickbank-ui/seller-design-system";
import {
	Close,
	EmailOutlined,
	LocationOnOutlined,
	PhoneOutlined,
	ScreenShareOutlined,
	WarningAmber,
	WebAssetOutlined
} from "@mui/icons-material";
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Link,
	Typography
} from "@mui/material";
import { Stack } from "@mui/system";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { Trans } from "react-i18next";

import { ApolloClient, SITE_SETTINGS, SUBMIT_VENDOR_COMPLAINT } from "../../Api";
import { AppContext } from "../../context";
import CopyableLink from "../CopyableLink/CopyableLink";

const breakpoints = DSBreakpoints.default;
const { cbNeutral, palette } = DSPalette;
const { primaryFont } = DSTypography;

const styles = {
	linkColumn: {
		"& .MuiStack-root": {
			display: "flex",
			flexDirection: "column",
			alignContent: "center",

			"&.email": {
				flexDirection: "row",
				alignItems: "center"
			}
		},
		"& .MuiLink-root": {
			color: palette.primary.main,

			"&.red": {
				color: palette.error.main,
				textDecorationColor: palette.error.main
			},
			"&:hover": {
				textDecoration: "none"
			}
		}
	},
	doubleLine: {
		marginBottom: "16px",
		wordBreak: "break-all",
		lineHeight: 1.1
	},
	header: {
		fontWeight: "600",
		color: palette.primary.main,
		marginBottom: "5px"
	},
	mobileViewMargin: {
		[breakpoints.down("sm")]: {
			marginTop: "24px",
			flexDirection: "column"
		}
	},
	phone: {
		margin: "24px 0 0",
		flexDirection: "row",
		paddingRight: "15px",

		[breakpoints.down("sm")]: {
			margin: "16px 0 0",
			flexDirection: "column",
			paddingRight: "0"
		}
	},
	returnContainer: {
		padding: "0 2rem 0 0",

		[breakpoints.up("lg")]: {
			padding: "0 3rem 0 0"
		}
	},
	addressDialogContent: {
		position: "relative",

		[breakpoints.up("xs")]: {
			minWidth: 350
		},
		"& a": {
			wordBreak: "break-word"
		},
		"& .MuiTypography-root": {
			color: cbNeutral[400]
		}
	},
	returnInstructions: {
		"& pre": {
			margin: 0,
			fontFamily: primaryFont,
			fontSize: "1rem",
			color: cbNeutral[400],
			whiteSpace: "pre-line"
		}
	},
	sellerAndCustomerInfoHeading: {
		margin: "24px 0",

		[breakpoints.down("sm")]: {
			margin: "16px 0 0"
		}
	}
};

const SellerInformation = ({ lineItems, pitchPage, receiptNo }) => {
	const [addressModalOpen, setAddressModalOpen] = useState(false);

	const handleAddressModal = () => setAddressModalOpen(!addressModalOpen);

	const { setAlertText, showAlert, setAlert, setActionSuccessful } = useContext(AppContext);

	const vendorId =
		lineItems && lineItems?.length > 0 ? lineItems[0]?.product?.vendor?.nickname : undefined;

	const { data: siteSettingsData } = useQuery(SITE_SETTINGS, {
		variables: { vendorId },
		context: {
			noAuth: true
		}
	});
	const customerService = siteSettingsData?.siteSettings?.customerService;

	const [submitVendorComplaint, { loading: submittingComplaint }] = useMutation(
		SUBMIT_VENDOR_COMPLAINT,
		{
			client: ApolloClient,
			onError: () => {
				!showAlert && setAlert(true);
				setAlertText(
					<Trans i18nKey="Common.error.unknown">
						An unknown error has occurred. Please contact customer support directly.
					</Trans>
				);
				setActionSuccessful(false);
			},
			onCompleted: ({ url }) => window.open(url)
		}
	);

	const uniqueArray = lineItems?.filter((item, index) => {
		const _item = JSON.stringify(item.product.vendor);
		return (
			index ===
			lineItems.findIndex(obj => {
				return JSON.stringify(obj.product.vendor) === _item;
			})
		);
	});

	return (
		<div>
			<Divider />
			<Typography variant="h3" sx={styles.sellerAndCustomerInfoHeading}>
				<Trans i18nKey="OrderDetails.sellerInfo">Seller Information</Trans>
			</Typography>

			{uniqueArray?.map((value, index) => {
				const vendor = value?.product?.vendor;

				return (
					<Grid
						container
						key={index}
						columnSpacing={{ xs: 0, md: 8 }}
						rowSpacing={{ xs: 2, sm: 4, md: 0 }}
					>
						<Grid item xs={12} md={6} lg={7}>
							<Typography
								variant="h3"
								sx={{
									paddingBottom: "16px",
									fontWeight: "400",
									color: palette.primary.main
								}}
							>
								{vendor?.brandName}
							</Typography>
							<Grid
								item
								xs={12}
								lg={
									value?.product?.shippable && vendor?.physicalReturnInstructions
										? 4
										: 12
								}
								sx={styles.linkColumn}
							>
								<Stack spacing={1}>
									{vendor?.email !== null && (
										<Stack className="email" direction="row" spacing={1}>
											<Link
												className="iconLink no-mouseflow"
												underline="always"
											>
												<EmailOutlined />
												{vendor?.email}
											</Link>

											<CopyableLink
												width="20px"
												height="20px"
												url={vendor?.email}
												className="no-mouseflow"
											/>
										</Stack>
									)}
									{vendor?.phone !== null && (
										<Link
											className="iconLink"
											underline="always"
											href={`tel:${vendor?.phone}`}
										>
											<PhoneOutlined />
											{vendor?.phone}
										</Link>
									)}
								</Stack>
							</Grid>
						</Grid>

						<Grid item xs={12} md={6} lg={5} sx={styles.linkColumn}>
							<Typography variant="h5" mb={1}>
								<Trans i18nKey="OrderDetails.otherLinks">Other Links</Trans>
							</Typography>
							<Stack spacing={1}>
								{pitchPage && (
									<Link
										className="iconLink "
										underline="always"
										color="primary"
										href={pitchPage}
										target="_blank"
										rel="noopener noreferrer"
									>
										<WebAssetOutlined />
										<Trans i18nKey="OrderDetails.visitSellerHomePage">
											Visit Seller's Homepage
										</Trans>
									</Link>
								)}
								{vendor?.supportSite && (
									<Link
										className="iconLink "
										underline="always"
										color="primary"
										href={vendor.supportSite}
										target="_blank"
										rel="noopener noreferrer"
									>
										<ScreenShareOutlined />
										<Trans i18nKey="OrderDetails.visitSellerSupportPage">
											Visit Seller's Support Page
										</Trans>
									</Link>
								)}
								{(customerService?.state || customerService?.countryCode) && (
									<>
										<Link
											className="iconLink"
											underline="always"
											color="primary"
											onClick={handleAddressModal}
										>
											<LocationOnOutlined />
											<Trans i18nKey="OrderDetails.viewSellerBusinessAddress">
												View Seller's Business Address
											</Trans>
										</Link>
										<Dialog
											open={addressModalOpen}
											onClose={handleAddressModal}
											maxWidth="sm"
											aria-labelledby="view-address-modal"
											aria-describedby="view-address"
											disablePortal // mouseflow
										>
											<DialogTitle>
												<Typography variant="h3">
													<Trans i18nKey="OrderDetails.businessAddressFor">
														Business Address for
													</Trans>{" "}
													{customerService?.brand}
												</Typography>
												<IconButton
													aria-label="close"
													onClick={handleAddressModal}
													sx={{
														position: "absolute",
														right: 8,
														top: 8,
														color: cbNeutral[700]
													}}
												>
													<Close />
												</IconButton>
											</DialogTitle>
											<DialogContent sx={styles.addressDialogContent}>
												<Box margin="0 0 20px">
													<div className="no-mouseflow">
														{!customerService?.residential ? (
															<>
																<Typography>
																	{customerService?.address1}
																</Typography>
																{customerService?.address2 && (
																	<Typography>
																		{customerService?.address2}{" "}
																		<br />
																	</Typography>
																)}
																<Typography>
																	{customerService?.city},{" "}
																	{customerService?.state}
																	<br />
																	{customerService?.zip},{" "}
																	{customerService?.countryCode}
																</Typography>
															</>
														) : (
															<Typography>
																{customerService?.state},{" "}
																{customerService?.countryCode}
															</Typography>
														)}
													</div>

													<Typography
														variant="subtitle1"
														sx={{
															padding: "1rem 0 0 1rem",
															textIndent: "-1rem",
															lineHeight: 1.3,
															fontWeight: 600
														}}
													>
														**{" "}
														{customerService?.residential && (
															<Trans i18nKey="SellerInformation.businessAddress.residential">
																There is no business address
																available for this Seller. All
																customer inquiries should be
																submitted to the seller by phone or
																email.
															</Trans>
														)}{" "}
														<Trans i18nKey="SellerInformation.businessAddress.pleaseReview">
															Please review the seller's return
															instructions before initiating a return.
														</Trans>
													</Typography>
												</Box>
											</DialogContent>
										</Dialog>
									</>
								)}
								<Link
									className="iconLink red"
									underline="always"
									sx={{ color: palette.error.main }}
									onClick={() => {
										if (!submittingComplaint) {
											submitVendorComplaint({
												variables: {
													vendor: vendor.nickname,
													receipt: receiptNo
												}
											});
										}
									}}
								>
									<WarningAmber color="error" />
									<Trans i18nKey="OrderDetails.reportSeller">Report Seller</Trans>
								</Link>
							</Stack>
						</Grid>
					</Grid>
				);
			})}
		</div>
	);
};

SellerInformation.propTypes = {
	lineItems: PropTypes.arrayOf(PropTypes.object),
	pitchPage: PropTypes.string,
	receiptNo: PropTypes.string
};

export default SellerInformation;
