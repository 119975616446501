import { gql } from "@apollo/client";

export const ORDER_DETAILS_INNER = gql`
	fragment OrderDetailsInnerFragment on Order {
		receiptNo
		orderDate
		shipping
		taxes
		subtotal
		discounts
		subtotalWithDiscounts
		total
		currencyCode
		paymentMethod
		last4
		recurring
		phoneNumberOnOrder
		refundStatus
		authDate
		tokenExPaymentMethod
		shippingCustomer {
			id
			fullName
			email
			phone
			address1
			address2
			city
			state
			zip
			country
		}
		billingCustomer {
			id
			fullName
			email
			phone
			address1
			address2
			city
			state
			zip
			country
		}
		lineItems {
			id
			title
			quantity
			price
			unitPrice
			subtotal
			subscriptionPrice
			itemSequenceNo
			refundable
			refundExpirationDate
			refunded
			refundType
			refundDate
			refunds {
				receiptNo
				orderDate
				total
				currencyCode
				last4
				paymentMethod
			}
			recurring
			refundExpirationDate
			coupon {
				code
				couponType
				value
			}
			supportTickets {
				id
				createTime
				updateTime
				ticketType
				topic
				note
				status
				closedDate
				closingComment
				closedBy
				openedByUser
				ticketSource
				reopenedDate
				escalatedDate
				note
				actions {
					id
					createTime
					updateTime
					actionType
					note
					role
				}
			}
			product {
				id
				title
				downloadUrl
				mobileDownloadUrl
				shippable
				digital
				orderType
				imagePath
				imageUrl
				imageAltText
				itemNo
				productType {
					id
					ebook
					software
					games
					video
					membership
				}
				vendor {
					id
					email
					brandName
					homepage
					nickname
					phone
					supportSite
					physicalReturnInstructions
				}
				priceModel {
					id
					recurringType
					frequency
					subsequentPaymentAmount
					trial
				}
			}
			shippingNotice {
				id
				carrier
				shippedDate
				trackingNumber
				trackingUrl
				comments
				received
			}
			returnShippingNotice {
				id
				carrier
				shippedDate
				trackingNumber
				comments
				received
			}
			paymentPlan {
				id
				activeStatus
				paymentsRemaining
				nextBillDate
				terminationDate
			}
		}
		pitchPage
		rebills {
			receiptNo
			orderDate
			total
			currencyCode
			lineItems {
				id
				refundable
				refunded
				refundDate
				refunds {
					receiptNo
					orderDate
					total
					currencyCode
				}
				paymentPlan {
					id
					activeStatus
					paymentsRemaining
					nextBillDate
					terminationDate
				}
				supportTickets {
					id
					createTime
					updateTime
					ticketType
					topic
					note
					status
					closedDate
					reopenedDate
					escalatedDate
					actions {
						id
						createTime
						updateTime
						actionType
						note
						role
					}
				}
			}
		}
		taxAddress {
			name
			taxId
			address1
			address2
			city
			country
			state
			zip
		}
	}
`;
