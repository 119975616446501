import { DSPalette, DSTypography } from "@clickbank-ui/seller-design-system";
import { Close } from "@mui/icons-material";
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Paper
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";
import Linkify from "react-linkify";

const { cbNeutral } = DSPalette;
const { primaryFont } = DSTypography;

const styles = {
	dialogTitle: {
		"&.MuiTypography-h2": {
			color: cbNeutral[400]
		}
	},
	dialogContent: {
		"& .MuiTypography-root": {
			color: cbNeutral[400]
		}
	},
	returnInstructions: {
		p: 1.5,
		background: cbNeutral[950],
		borderColor: cbNeutral[700],

		"& pre": {
			margin: 0,
			fontFamily: primaryFont,
			fontSize: "1rem",
			color: cbNeutral[400],
			whiteSpace: "pre-line"
		}
	}
};

const ReturnInstructionsModal = ({ handleClose, instructions }) => {
	const linkifyText = returnText => {
		return (
			<Linkify
				componentDecorator={(decoratedHref, decoratedText, key) => (
					<a target="blank" href={decoratedHref} key={key}>
						{decoratedText}
					</a>
				)}
			>
				<pre>{returnText}</pre>
			</Linkify>
		);
	};

	return (
		<>
			<DialogTitle variant="h2" sx={styles.dialogTitle}>
				<Trans i18nKey="ReturnInstructionsModal.title.returnProductInstructions">
					Return Product Instructions
				</Trans>
				<IconButton aria-label="close" onClick={handleClose} className="closeBtn">
					<Close />
				</IconButton>
			</DialogTitle>

			<DialogContent sx={styles.dialogContent}>
				<Paper elevation={0} variant="outlined" sx={styles.returnInstructions}>
					{linkifyText(instructions)}
				</Paper>
			</DialogContent>

			<DialogActions>
				<Button fullWidth color="secondary" onClick={handleClose}>
					<Trans i18nKey="Common.done">Done</Trans>
				</Button>
			</DialogActions>
		</>
	);
};

ReturnInstructionsModal.propTypes = {
	handleClose: PropTypes.func,
	instructions: PropTypes.string
};

export default ReturnInstructionsModal;
