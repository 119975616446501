import { useLazyQuery } from "@apollo/client";
import {
	DSBreakpoints,
	DSPalette,
	DSShadows,
	DSTypography
} from "@clickbank-ui/seller-design-system";
import { ChevronLeft, Print } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import { RECEIPT_BY_RECEIPT, TAX_CODE } from "../../Api";
import { taxName } from "../../constants";
import { currencyFormatter } from "../../util/Currencyhelpers";
import { getReceiptFromDolToken } from "../../util/authHelpers.js";
import ReceiptFooter from "./ReceiptFooter";
import ReceiptHeading from "./ReceiptHeading";

const breakpoints = DSBreakpoints.default;
const { cbNeutral, palette } = DSPalette;
const { cbShadows } = DSShadows;
const { fontWeightSemi } = DSTypography;

const styles = {
	container: {
		marginTop: "24px",
		padding: "60px",
		background: palette.common.white,
		boxShadow: cbShadows[2],

		[breakpoints.down("md")]: {
			padding: "30px"
		},
		"@media print": {
			margin: 0,
			padding: 0,
			boxShadow: "none"
		},
		"& *": {
			color: cbNeutral[100]
		},
		"& .MuiTypography-h5": {
			paddingBottom: "16px",
			fontWeight: fontWeightSemi,

			"@media print": {
				fontSize: 16
			}
		},
		"& .MuiTypography-body2": {
			"@media print": {
				fontSize: 14
			}
		}
	},
	imageContainer: {
		"& img, & svg": {
			maxWidth: 160,
			height: "auto",
			padding: "0 0 1rem",

			[breakpoints.up("sm")]: {
				padding: "0 1rem"
			}
		}
	},
	lineItemDiv: {
		display: "flex",
		flexDirection: "column",
		padding: "24px 0",
		borderBottom: `1px solid ${cbNeutral[900]}`,

		[breakpoints.up("sm")]: {
			flexDirection: "row"
		}
	},
	summaryDiv: {
		padding: "24px",
		paddingLeft: 0,
		borderRight: `1px solid ${cbNeutral[900]}`,
		width: "50%",
		verticalAlign: "top",
		boxSizing: "border-box",
		display: "inline-block",

		[breakpoints.down("md")]: {
			display: "block",
			width: "100%",
			paddingRight: 0,
			borderRight: 0
		}
	},
	summaryLeftCol: {
		textAlign: "left",
		width: "50%",
		display: "inline-block"
	},
	summaryRightCol: {
		textAlign: "right",
		width: "50%",
		display: "inline-block"
	},
	paymentDiv: {
		padding: "24px",
		width: "50%",
		boxSizing: "border-box",
		display: "inline-block",

		[breakpoints.down("md")]: {
			display: "block",
			width: "100%",
			paddingLeft: 0,
			paddingRight: 0
		}
	},
	paymentLeftCol: {
		textAlign: "left",
		width: "33%",
		display: "inline-block"
	},
	paymentRightCol: {
		textAlign: "right",
		width: "67%",
		display: "inline-block"
	},
	taxDiv: {
		borderBottom: `1px solid ${cbNeutral[900]}`,
		padding: "24px 0",
		textAlign: "center"
	},
	customerInfoDiv: {
		display: "flex",
		flexDirection: "column",
		rowGap: "1.5rem",
		boxSizing: "border-box",
		paddingBottom: "24px",
		wordBreak: "break-all",

		[breakpoints.up("md")]: {
			flexDirection: "row",
			justifyContent: "space-between",
			columnGap: "2rem"
		}
	}
};

export default () => {
	const history = useHistory();

	const [orderDetails, setOrderDetails] = useState(undefined);
	const [receiptNo, setReceiptNo] = useState("");
	const [taxCode, setTaxCode] = useState("");

	const orderDate = new Date(orderDetails?.orderDate);
	const formattedOrderDate = orderDate.toLocaleDateString("en-US");
	const showTaxInfo = orderDetails?.shippingCustomer.country !== "US" && orderDetails?.taxes > 0;
	const isShippable = orderDetails?.lineItems?.some(item => item.shippable === true);
	const couponItem = orderDetails?.lineItems?.filter(item => item.coupon !== null)[0];
	const country = orderDetails?.shippingCustomer?.country;
	const taxType = () => {
		if (taxName.gst.includes(country)) return <Trans i18nKey="Receipt.gst">GST</Trans>;
		else if (taxName.vat.includes(country)) return <Trans i18nKey="Receipt.vat">VAT</Trans>;
		return <Trans i18nKey="Common.taxes">Taxes</Trans>;
	};

	const [getReceipt, { data: receiptData, error: receiptError }] = useLazyQuery(
		RECEIPT_BY_RECEIPT,
		{
			variables: { receipt: receiptNo }
		}
	);

	const [getTaxCode, { data: taxCodeData }] = useLazyQuery(TAX_CODE, {
		variables: { country: orderDetails?.shippingCustomer.country }
	});

	const printReceipt = () => {
		window.print();
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const receiptNo = urlParams.get("receiptNo") || getReceiptFromDolToken();
		setReceiptNo(receiptNo);
		getReceipt();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (receiptData) {
			setOrderDetails(receiptData.receiptByReceiptNo);
			receiptData.receiptByReceiptNo.shippingCustomer.country !== "US" &&
				receiptData.receiptByReceiptNo.taxes > 0 &&
				getTaxCode();
		}
		receiptError && history.push("/orderHistory?status=UnableToFindOrder");
	}, [receiptData, receiptError, history, getTaxCode]);

	useEffect(() => {
		taxCodeData && setTaxCode(taxCodeData.taxCodeByCountry.taxCode);
	}, [taxCodeData, setTaxCode]);

	return (
		<>
			<Stack
				direction={{ xs: "column", sm: "row" }}
				justifyContent="space-between"
				sx={{ "@media print": { display: "none" } }}
			>
				<Button
					size="small"
					color="secondary"
					startIcon={<ChevronLeft />}
					sx={{ mb: { xs: 1, sm: 0 } }}
					id="backtoOrderHistory"
					onClick={() => {
						history.push("/");
					}}
				>
					{getReceiptFromDolToken() === "NO_RECEIPT_FOUND" ? (
						// We might be able to do better: Button will not be displayed if authenticated via DOL and login.
						<Trans i18nKey="Common.backToOrderHistory">Back to Order History</Trans>
					) : (
						<Trans i18nKey="Common.customerSupportHome">Customer Support Home</Trans>
					)}
				</Button>
				<Button
					size="small"
					color="primary"
					startIcon={<Print />}
					id="print"
					onClick={printReceipt}
				>
					<Trans i18nKey="Common.print">Print</Trans>
				</Button>
			</Stack>

			<Box sx={styles.container} zIndex="tooltip">
				{!orderDetails ? (
					<Grid container justifyContent="center" alignItems="center">
						<CircularProgress />
					</Grid>
				) : (
					<>
						<ReceiptHeading
							receipt={orderDetails.receiptNo}
							orderDate={formattedOrderDate}
						/>
						{orderDetails?.lineItems?.map((lineItem, index) => (
							<Box sx={styles.lineItemDiv} key={index}>
								{lineItem?.imagePath && (
									<Box sx={styles.imageContainer}>
										<img src={lineItem.imageUrl} alt={lineItem.imageAltText} />
									</Box>
								)}
								<Box>
									<Typography variant="h3">{lineItem.title}</Typography>
									<Typography variant="h4">
										{currencyFormatter(
											lineItem.subtotal,
											orderDetails.currencyCode
										)}
									</Typography>
								</Box>
							</Box>
						))}

						<Box sx={styles.summaryDiv}>
							<Typography variant="h4">
								<Trans i18nKey="Common.orderSummary">Order Summary</Trans>
							</Typography>
							<div>
								<Box sx={styles.summaryLeftCol}>
									<Typography variant="body2">
										<Trans i18nKey="Common.subtotal">Subtotal</Trans>:
									</Typography>
								</Box>
								<Box sx={styles.summaryRightCol}>
									<Typography variant="body2" sx={{ fontWeight: "600" }}>
										{currencyFormatter(
											orderDetails.subtotal,
											orderDetails.currencyCode
										)}
									</Typography>
								</Box>
							</div>
							{isShippable && (
								<div>
									<Box sx={styles.summaryLeftCol}>
										<Typography variant="body2">
											<Trans i18nKey="Common.shippingHandling">
												Shipping & Handling
											</Trans>
											:
										</Typography>
									</Box>
									<Box sx={styles.summaryRightCol}>
										<Typography variant="body2" sx={{ fontWeight: "600" }}>
											{orderDetails?.shipping === 0 ? (
												<Trans i18nKey="Receipt.free">Free</Trans>
											) : (
												currencyFormatter(
													orderDetails?.shipping,
													orderDetails.currencyCode
												)
											)}
										</Typography>
									</Box>
								</div>
							)}
							{couponItem && (
								<div>
									<Box sx={styles.summaryLeftCol}>
										<Typography variant="body2">
											{couponItem?.coupon.code} -{" "}
											<Trans i18nKey="Receipt.couponValue">
												{{
													value: couponItem?.coupon.value
												}}
												% OFF
											</Trans>
										</Typography>
									</Box>
									<Box sx={styles.summaryRightCol}>
										<Typography variant="body2" sx={{ fontWeight: "600" }}>
											{currencyFormatter(
												orderDetails.discounts,
												orderDetails.currencyCode
											)}
										</Typography>
									</Box>
								</div>
							)}
							<div>
								<Box sx={styles.summaryLeftCol}>
									<Typography variant="body2">{taxType()}:</Typography>
								</Box>
								<Box sx={styles.summaryRightCol}>
									<Typography variant="body2" sx={{ fontWeight: "600" }}>
										{currencyFormatter(
											orderDetails.taxes,
											orderDetails.currencyCode
										)}
									</Typography>
								</Box>
							</div>
							<Box
								sx={{
									borderBottom: `1px solid ${cbNeutral[900]}`,
									margin: "8px 0px"
								}}
							/>
							<div>
								<Box sx={styles.summaryLeftCol}>
									<Typography
										variant="body2"
										sx={{
											fontWeight: "600"
										}}
									>
										<Trans i18nKey="Common.total">Total</Trans>:
									</Typography>
								</Box>
								<Box sx={styles.summaryRightCol}>
									<Typography
										variant="body2"
										sx={{
											fontWeight: "600"
										}}
									>
										{currencyFormatter(
											orderDetails.total,
											orderDetails.currencyCode
										)}
									</Typography>
								</Box>
							</div>
						</Box>

						<Box sx={styles.paymentDiv}>
							<Typography variant="h4">
								<Trans i18nKey="Receipt.paymentInfo">Payment Information</Trans>
							</Typography>
							<Box sx={styles.paymentLeftCol}>
								<Typography variant="body2">
									<Trans i18nKey="Receipt.method">Method</Trans>:
								</Typography>
							</Box>
							<Box sx={styles.paymentRightCol}>
								<Typography variant="body2" className="no-mouseflow">
									{["PYPL", "PAYPAL_ADAPTIVE", "PAYPAL_COMMERCE"].includes(
										orderDetails?.paymentMethod
									) ? (
										<Trans i18nKey="Receipt.paypal">PayPal</Trans>
									) : orderDetails.last4 ? (
										<Trans i18nKey="Receipt.card">
											{{
												cardType: orderDetails.paymentMethod
											}}{" "}
											ending in {{ last4: orderDetails?.last4 }}
										</Trans>
									) : (
										orderDetails?.paymentMethod
									)}
								</Typography>
							</Box>
						</Box>

						<Box sx={styles.taxDiv}>
							<Typography variant="body2" sx={{ fontWeight: "700" }}>
								**
								<Trans i18nKey="Receipt.taxWarning">
									Taxes subject to change based on location and rate adjustments.
								</Trans>
								**
							</Typography>
						</Box>

						<Typography variant="h4" sx={{ m: "24px 0 12px" }}>
							<Trans i18nKey="Receipt.customerInfo">Customer Information</Trans>
						</Typography>

						<Box sx={styles.customerInfoDiv}>
							<Box
								sx={
									showTaxInfo && isShippable
										? styles.contactDivWithTax
										: styles.contactDiv
								}
							>
								<Typography variant="body2">
									<strong>
										<Trans i18nKey="Receipt.contact">Contact</Trans>
									</strong>
								</Typography>
								<Typography variant="body2" className="no-mouseflow">
									{orderDetails?.billingCustomer.email}
									{orderDetails?.billingCustomer.phone && (
										<>
											<br />
											{orderDetails?.billingCustomer.phone}
										</>
									)}
								</Typography>
							</Box>
							{isShippable && (
								<Box sx={{ ...(showTaxInfo && styles.shippingDivWithTax) }}>
									<Typography variant="body2">
										<strong>
											<Trans i18nKey="Common.shippingAddress">
												Shipping Address
											</Trans>
										</strong>
									</Typography>
									<Typography variant="body2" className="no-mouseflow">
										{orderDetails?.shippingCustomer.fullName}
										<br />
										{orderDetails?.shippingCustomer.address1}
										{orderDetails?.shippingCustomer.address2 && (
											<>
												<br />
												{orderDetails?.shippingCustomer.address2}
											</>
										)}
										<br />
										{orderDetails?.shippingCustomer.city}
										{orderDetails?.shippingCustomer.city &&
											orderDetails?.shippingCustomer.state &&
											","}
										{orderDetails?.shippingCustomer.city && " "}
										{orderDetails?.shippingCustomer.state}
										{orderDetails?.shippingCustomer.state && " "}
										{orderDetails?.shippingCustomer.zip}
									</Typography>
								</Box>
							)}
							{showTaxInfo && orderDetails?.taxAddress && (
								<Box sx={isShippable ? styles.taxInfoDiv : styles.shippingDiv}>
									<Typography variant="body2">
										<strong>
											<Trans i18nKey="Receipt.taxAddress">
												Tax Information
											</Trans>
										</strong>
									</Typography>
									<div className="no-mouseflow">
										<Typography variant="body2">
											{orderDetails?.taxAddress?.name}
										</Typography>
										<Typography variant="body2">
											{orderDetails?.taxAddress?.address1}
										</Typography>
										{orderDetails?.taxAddress?.address2 && (
											<Typography variant="body2">
												{orderDetails?.taxAddress?.address2}
											</Typography>
										)}
										<Typography variant="body2">
											{orderDetails?.taxAddress?.city}
											{orderDetails?.taxAddress?.city &&
												orderDetails?.taxAddress?.state &&
												","}
											{orderDetails?.taxAddress?.city && " "}
											{orderDetails?.taxAddress?.state}
											{orderDetails?.taxAddress?.state && " "}
											{orderDetails?.taxAddress?.zip}
										</Typography>
										<Typography variant="body2">
											{orderDetails?.taxAddress?.country}
										</Typography>
										<Typography variant="body2">
											{orderDetails?.taxAddress?.taxId}
										</Typography>
									</div>
								</Box>
							)}
						</Box>
						<ReceiptFooter showTaxInfo={showTaxInfo} taxCode={taxCode} />
					</>
				)}
			</Box>
		</>
	);
};
